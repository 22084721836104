<template>
  <div class="container has-text-centered">
    <h1 class="title has-text-centered">Help and Support</h1>
    <img src="@/assets/images/illustration-help.svg" class="my-6">
    <p class="is-size-5 has-text-weight-semibold mb-3">
      Need help? We are online, Message us
      <a>
        now!
      </a>
    </p>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {}
}
</script>

<style>

</style>